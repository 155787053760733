.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}
.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}
.flex-grid-thirds .col {
  width: 32%;
}

.footerButton {
  display: inline-block;

  /*	width: 64pt;
	height: 64pt;
	background-color: blue;
  position: absolute;
*/
  width: 112px;
  height: 90px;
  /*  left: 8px;
  top: 613px;
*/
  background: #e0a86e;
  border: 1px solid #f2a762;
  box-sizing: border-box;
  border-radius: 10px;
}

a.footerButton {
  text-decoration: none;
}

.footerButtonCenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footerButtonImage {
  margin-bottom: 5px;
}

.footerButton .text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #3a1d0b;
}

.mapContainer {
  bottom: 220px;

  /*height: 100pt;*/
  height: 100vh;
  width: 100%;
}

.myBottomBar {
  position: absolute;
  width: 100%;
  height: 220px;
  bottom: 0;
}
