/* results-div1 <-- .variableHeightContainer {
  height: 100vh;
  width: 100%;

  overflow: hidden;
}
*/

.scrollable {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.resultsList {
  margin-left: 0;
  margin-right: 0;
  margin-top: 60px;
  margin-bottom: 16px;

  text-align: left;
}

.loadingIndicator {
  margin-top: 60px;
  height: 300px;
}

/* results-div3 <-- .resultsList {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;

	text-align: left;
	max-height:100%;
}
*/

.result {
  padding-left: 16px;
  padding-right: 16px;

  border: 0;
  border-bottom: 1px solid lightgray;
}

.result .time {
  white-space: nowrap;
  font-size: 140%;
  font-weight: bold;

  margin-top: 8px;
  margin-left: 0px;
  margin-bottom: 8px;

  padding-top: 0px;
  padding-left: 0px;
}

.result .info {
  color: gray;

  margin-bottom: 8px;
}

a.result,
a.result:active,
a.result:hover,
a.result:visited {
  text-decoration: none;
}

.result .route {
  /*background-color: red;*/
  color: gray;

  margin-top: 8px;
  margin-bottom: 8px;
}

.routeSteps {
  display: block;

  list-style: none;
  margin-left: 0;
  padding-top: 0px;
  padding-left: 0px;
  margin-right: 0px;
  width: 100%;

  vertical-align: center;
}

.routeSteps li {
  /*background-color: skyblue;*/
  margin: 2px;
  display: inline;
  height: 31px;

  word-break: break-word;
}

span.terminal {
  background-color: gray;
  color: white;
  width: 1em;
  height: 1em;
  padding: 2px;
  margin-right: 5px;
}

.routeSteps li svg {
  position: relative;
  top: 4px;
  height: 20px;
  max-height: 20px;
  color: black;
}
