.header {
  width: 100%;
  height: 233px;

  background: linear-gradient(110.46deg, rgba(248, 235, 169, 0.79) 1.52%, #e0a86e 97.13%);
}

.header .background {
  width: 100%;
  height: 100%;
}

.header .tagline {
  padding-top: 40pt;

  font-family: Roboto;
  font-weight: normal;
  font-size: 18px;
  text-align: center;

  color: #3a1d0b;
}

.header .heading {
  text-align: center;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 75px;

  color: #3a1d0b;

  margin-top: 36px;

  text-shadow: 1px 1px white;
}

.buttons button {
  min-width: 256px;
  min-height: 35px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.buttons .primary {
  margin-top: 26px;
  margin-left: auto;
  margin-right: auto;

  background: #e0a86e;
  border: 0px white;
  border-radius: 30.5px;
  color: #3a1d0b;
}

.buttons .secondary {
  margin-top: 26px;
  margin-left: auto;
  margin-right: auto;

  background: white;
  border: 1px solid #e0a86e;
  box-sizing: border-box;
  border-radius: 30.5px;
}

.buttons .text {
  margin-top: 26px;
  margin-left: auto;
  margin-right: auto;

  background: none;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #3a1d0b;
}

.carousel__inner-slide {
}

.slideHeader {
  width: 100%;
  background: #3a1d0b;

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;

  color: #f9e1c9;
}

.slideText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.slideControls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.extra {
}

.carousel__dot-group {
  margin-top: 0;
  margin-bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__dot {
  width: 16px;
  height: 16px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 0;
  margin-bottom: 0;

  border: 0px;
  border-radius: 8px;
  background: #979797;
}

.carousel__dot--selected {
  background: #e0a86e;
}

.carousel__next-button {
  border: 0;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-left: 12px;

  background: none;
}

.carousel__back-button {
  border: 0;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-right: 12px;

  background: none;
}
