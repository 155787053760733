.routeDetailsList {
  margin-left: 0;
  margin-right: 0;
  margin-top: 80px;
  margin-bottom: 16px;

  text-align: left;
}

.routeDetailsList .info {
  text-align: left;
  color: gray;

  margin-bottom: 0;
  margin-left: 0px;
  margin-right: 16px;
  margin-bottom: 20px;

  padding-left: 24px;

  position: relative;
}

.routeStep {
  padding-left: 16px;
  padding-right: 16px;

  border: 0;
  /* border-bottom: 1px solid lightgray; */
}

.routeStep {
  min-height: 140px;
}

.routeStepLast {
  min-height: 40px;
}

.routeStep .time {
  white-space: nowrap;
  font-size: 125%;
  font-weight: bold;

  margin-top: 8px;
  margin-left: 0px;
  margin-bottom: 8px;

  padding-top: 0px;
  padding-left: 0px;
}

.routeStep .transferDepartureTime {
  white-space: nowrap;
  font-size: 125%;
  font-weight: bold;

  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 8px;

  padding-top: 0px;
  padding-left: 0px;
}

.routeStep .transferArrivalTime {
  white-space: nowrap;
  font-size: 125%;
  font-weight: bold;

  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 8px;

  padding-top: 0px;
  padding-left: 0px;
}

.routeStep .info {
  color: gray;

  margin-bottom: 8px;
}

.routeStep .info .time {
  text-align: center;

  color: black;

  font-size: 125%;
  font-weight: bold;

  margin: 0;
  padding: 0;
  position: absolute;
  left: 0px;
  width: 70px;
}

.routeStep .info .transferDepartureTime {
  text-align: center;

  color: black;

  font-size: 125%;
  font-weight: bold;

  margin-top: 8px;
  padding: 0;
  position: absolute;
  left: 0px;
  width: 70px;
}

.routeStep .info .transferArrivalTime {
  text-align: center;

  color: black;

  font-size: 125%;
  font-weight: bold;

  margin-top: -8px;
  padding: 0;
  position: absolute;
  left: 0px;
  width: 70px;
}

.routeStep .info .dot {
  color: white;
  border-radius: 10px;
  z-index: 10;

  text-align: center;

  margin: 0;
  padding: 0;
  position: absolute;
  left: 92px;
  top: 0px;
  width: 20px;
  height: 20px;

  display: block;
}

/* dotted line background */
.routeStep .instructions {
  margin-left: 100px;
  padding-left: 24px;

  margin-right: 0px;

  border: 0;
  border-left-width: 4px;
  border-left-style: dotted;
  border-left-color: green;

  min-height: 128px;

  padding-top: 0px;
  padding-bottom: 8px;
}

.routeStep .instructions .location {
  color: black;
  font-weight: bold;

  margin: 0;
  padding: 0;

  padding-bottom: 12px;
}

.routeStep .instructions .methodContainer {
  display: flex;
  flex-direction: row;
}

.routeStep .instructions .methodIcon {
  margin: 0;
  padding: 0;

  width: 24px;
  height: 24px;

  margin-right: 8px;
}

.routeStep .instructions .method {
  min-height: 6em;
}

.routeStepLast .info .dot {
  color: white;
  border-radius: 10px;
  z-index: 10;

  text-align: center;

  margin: 0;
  padding: 0;
  position: absolute;
  left: 92px;
  top: 6px;
  width: 20px;
  height: 20px;

  background-color: gray;

  display: block;
}

.routeStepLast .instructionsLast {
  margin-left: 100px;
  padding-left: 24px;

  margin-right: 0px;

  padding-top: 5px;
  padding-bottom: 0px;
}

.routeStepLast .instructionsLast .location {
  color: black;
  font-weight: bold;
}

button.mainButton {
  display: block;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  height: 35px;

  background: #e0a86e;
  border-radius: 30.5px;
  border: 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: center;

  color: #3a1d0b;

  margin-top: 33px;
}

.disabledButton {
  background: #ccc !important;
  margin-top: 0 !important;
}

.bottomBar {
  bottom: 10px;
  height: 35vh;
}

.notKagaCivil {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin: 16px;
  text-align: center;

  color: red;

  line-height: 1em;
}