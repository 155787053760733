/* --- 全体をクリックできるようにする --- */
input#datePicker[type="date"] {
    position: relative;
}

input#datePicker[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
}

/* --- */