.dateTextField {
  width: 100%;
}

form.optionsForm {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

div.block {
  /*border: 2px solid blue;*/
  margin-bottom: 20px;
}

div.transportationOption {
  text-align: left;
  font-size: 20pt;
  /*background-color: green;*/

  padding: 4px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  /*margin-bottom: 10px;*/
}

div.transportationOption label {
  display: block;
  /*background-color: red;*/

  height: 2em;

  padding-top: 4px;
  padding-bottom: 0px;

  width: 100%;

  margin-bottom: 0px;
}

div.time {
  display: block;
  /*background-color: yellow;*/
}

div.time input[type="datetime-local"] {
  font-size: 16pt;
  display: block;
  /*background-color: red;*/

  margin-left: auto;
  margin-right: auto;
}

div.transportationOption input[type="checkbox"] {
  /* larger checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);

  padding-top: 8px;
  padding-bottom: 4px;

  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

/* #dateTimePicker {
  width: 100%;
} */

input[type='datetime-local'] {
  width: 100%;
  /* border-radius: 5px;
  padding: 8px;
  border-color: rgba(0, 0, 0, 0.54); */
}

/* iOS Styles */
input::-webkit-date-and-time-value {
  text-align: left;
}

/* --- 全体をクリックできるようにする --- */
input#dateTimePicker[type="datetime-local"] {
  position: relative;
}

input#dateTimePicker[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}
/* --- */