form {
  padding: 0;
}

.fieldset {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  background-color: red;

  height: 40px;
}

div.searchForm label {
  display: flex;
  height: 50px;
  line-height: 50px;
  margin-top: 0;
  margin-bottom: 8px;
  padding: 0;

  margin-left: 20px;
  margin-right: 20px;

  padding-left: 0px;
  padding-right: 0px;
}

.swapButton {
  min-width: 104px;
  height: 30px;
  margin-left: auto;
  margin-right: 36px;
  border: 0;

  background: #e0a86e;
  border-radius: 3px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #3a1d0b;
}

.optionsButton {
  display: block;

  margin-left: auto;
  margin-right: 36px;
  border: 0;
  background-color: #00000000;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #3a1d0b;
}

/*@media (prefers-color-scheme: dark) {
	.optionsButton {
		color: white;
	}
}
*/
.label {
  width: 54px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  text-align: left;

  color: #3a1d0b;

  margin-top: auto;
  margin-bottom: auto;
}

/*@media (prefers-color-scheme: dark) {
	.label {
		color: white;
	}
}*/

div.searchForm input {
  /*
	margin-left: 14px;
	margin-right: 20px;
	*/

  background: #eeeeee;
  border-radius: 5px;
  border-width: 0px;
  height: 27px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  width: 269px;
  margin-left: 14px;

  flex: none;
  box-sizing: border-box;

  margin-top: auto;
  margin-bottom: auto;
}

/*@media (prefers-color-scheme: dark) {
	div.searchForm input {
		background: black;
		border-color: white;
		color: white;
	}
}*/

.searchButton {
  display: block;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;

  width: 256px;
  height: 35px;

  background: #e0a86e;
  border-radius: 30.5px;
  border: 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: center;

  color: #3a1d0b;

  margin-top: 33px;
}

.searchButton:disabled {
  background: #eeeeee;
  color: rgba(58, 29, 11, 0.6);
}

.warningMessage {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin: 16px;

  color: #3a1d0b;

  line-height: 1em;
}