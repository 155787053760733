.suggestionsList {
  margin-left: 0;
  margin-right: 0;

  text-align: left;

  height: 58vh;
  overflow: scroll;

  width: 100%;
}

.suggestion {
  margin-left: 0;
  margin-right: 0;
  padding-left: 16px;
  padding-right: 16px;

  border: 0;
  border-bottom: 1px solid lightgray;

  font-size: 140%;

  padding-top: 8px;
  padding-bottom: 8px;
}

.suggestion:hover {
  background-color: #eee;
}

a.suggestion,
a.suggestion:active,
a.suggestion:hover,
a.suggestion:visited {
  text-decoration: none;
}

.locationSearchHeader {
  background: #3a1d0b;
  color: #f9e1c9;

  padding-left: 21px;
  line-height: 21px;
  padding-top: 2px;
  padding-bottom: 2px;

  /* 検索履歴 */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.suggestionsList .disabled {
  color: lightgray;
}

.suggestionsList:hover .disabled {
  color: lightgray;
  background-color: white;
}
