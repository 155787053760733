.header {
  width: 100%;
  height: 233px;

  background: linear-gradient(110.46deg, rgba(248, 235, 169, 0.79) 1.52%, #e0a86e 97.13%);
}

.header .background {
  width: 100%;
  height: 100%;
}

.header .tagline {
  padding-top: 40pt;

  font-family: Roboto;
  font-weight: normal;
  font-size: 18px;
  text-align: center;

  color: #3a1d0b;
}

.header .heading {
  text-align: center;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 75px;

  color: #3a1d0b;

  margin-top: 36px;

  text-shadow: 1px 1px white;
}

.guidanceHeader {
  width: 100%;
  background: #3a1d0b;

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;

  color: #f9e1c9;
}