.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.search-container input {
  height: 32px;
  width: 90%;
  padding: 0px 12px 0px 12px;

  background-color: #eee;
}
